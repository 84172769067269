<template>
    <div>
    
        <router-view class="flex-1 overflow-hidden" />
    </div>
</template>


<script>
export default {
  data() {
    return {
      auth: false,
    };
  },
  computed: {
    user() {
      return this.$store.state.auth.user;
    },
  }
}
</script>